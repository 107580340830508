import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppHttpHeaders, AppHttpParams, HttpBaseOptions, HttpGetOptions } from '@core/models/http.models';
import { QueryFilters, QueryParams } from '@core/models/query-params.models';
import { HttpUtils } from '@core/utils/http.utils';
import { Observable } from 'rxjs';

type HttpOptions<R> = {
  responseType?: R;
  headers?: HttpHeaders;
  params?: HttpParams;
};

@Injectable({ providedIn: 'root' })
export class HttpApi {
  constructor(private readonly http: HttpClient) {}

  get<T>(url: string, options?: HttpGetOptions): Observable<T> {
    return this.http.get<T>(url, this.handleOptions(options));
  }

  post<T>(url: string, body: unknown | null, options?: HttpGetOptions): Observable<T> {
    return this.http.post<T>(url, body, this.handleOptions(options));
  }

  patch<T>(url: string, body: unknown | null, options?: HttpGetOptions): Observable<T> {
    return this.http.patch<T>(url, body, this.handleOptions(options));
  }

  put<T>(url: string, body: unknown | null, options?: HttpGetOptions): Observable<T> {
    return this.http.put<T>(url, body, this.handleOptions(options));
  }

  delete<T>(url: string, options?: HttpGetOptions): Observable<T> {
    return this.http.delete<T>(url, this.handleOptions(options));
  }

  private handleOptions<R>(options: HttpBaseOptions | undefined): HttpOptions<R> {
    if (!options) return {};
    const params: HttpParams = this.handleParams((options.params as AppHttpParams<QueryParams>) || {});

    let headers: HttpHeaders = this.transformHeaders(options.headers as AppHttpHeaders);

    if (options.skipSuccessMessage) {
      headers = HttpUtils.skipSuccessMessage(headers);
    }

    return { ...options, params, headers } as HttpOptions<R>;
  }

  private handleParams(params: AppHttpParams<QueryParams>): HttpParams {
    let httpParams: HttpParams = new HttpParams();

    Object.keys(params).forEach((key: string) => {
      if (key === 'filters') {
        httpParams = HttpUtils.filtersToHttpParams(httpParams, params.filters as QueryFilters);
      } else {
        httpParams = httpParams.append(key, params[key as keyof QueryParams] as string | number | boolean);
      }
    });

    return httpParams;
  }

  private transformHeaders(headers: AppHttpHeaders): HttpHeaders {
    let _headers: HttpHeaders = new HttpHeaders();

    if (headers instanceof Object) {
      for (const key in headers) {
        _headers = _headers.set(key, headers[key]);
      }
    }

    return _headers;
  }
}
