import { Injectable } from '@angular/core';
import { User } from '@core/models/entities/user.models';
import { BehaviorSubject, finalize, Observable, tap } from 'rxjs';
import { Parent } from '@core/models/entities/parent.models';
import { Role } from '@core/constants/roles.constants';
import { HttpApi } from './http.api';

@Injectable({ providedIn: 'root' })
export class ProfileApi {
  private _fetcher$: Observable<User> | null = null;
  private readonly _user$ = new BehaviorSubject<User | null>(null);

  constructor(private readonly httpApi: HttpApi) {}

  get user$(): Observable<User | null> {
    if (this.user) return this._user$.asObservable();
    if (this._fetcher$) return this._fetcher$;
    this._fetcher$ = new Observable<User>((observer) => this.fetch().subscribe(observer));
    return this._fetcher$;
  }

  get user(): User | null {
    return this._user$.value;
  }

  set user(user: User | null) {
    this._user$.next(user);
  }

  protected fetch(): Observable<User> {
    return this.httpApi
      .get<User>('user')
      .pipe(tap((user: User) => this._user$.next(user)))
      .pipe(finalize(() => (this._fetcher$ = null)));
  }

  patch(user: Partial<User>) {
    const currentUser = this.user || ({} as User);
    this._user$.next({ ...currentUser, ...user });
  }

  isIntakeFormCompleted(): boolean {
    const { role } = this.user || ({} as User);
    if (role !== Role.PARENT) return true;
    return !!(this.user as Parent).info;
  }

  deleteProfile(): Observable<boolean> {
    return this.httpApi.delete(`user`, { skipSuccessMessage: true });
  }
}
