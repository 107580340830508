export enum Role {
  PARENT = 'parent',
  ADMIN = 'admin',
  SUPER_ADMIN = 'superadmin',
  COORDINATOR = 'coordinator',
  GENERAL_MANAGER = 'general_manager',
  DIRECTOR_MANAGER = 'director_manager',
}

export const FULL_ACCESS_ADMIN_ROLES = [Role.ADMIN, Role.SUPER_ADMIN, Role.GENERAL_MANAGER];

export const ADMIN_ROLES = [
  Role.ADMIN,
  Role.GENERAL_MANAGER,
  Role.SUPER_ADMIN,
  Role.COORDINATOR,
  Role.DIRECTOR_MANAGER,
];
export const PARENT_ROLES = [Role.PARENT];

export const CHECKLIST_SESSIONS_ROLES = [
  Role.ADMIN,
  Role.SUPER_ADMIN,
  Role.GENERAL_MANAGER,
  Role.COORDINATOR,
  Role.DIRECTOR_MANAGER,
  Role.PARENT,
];

export const UPDATE_SESSIONS_ROLES = [Role.ADMIN, Role.SUPER_ADMIN, Role.GENERAL_MANAGER];

export const UPDATE_SESSIONS_NOTES_ROLES = [
  Role.ADMIN,
  Role.SUPER_ADMIN,
  Role.GENERAL_MANAGER,
  Role.COORDINATOR,
  Role.DIRECTOR_MANAGER,
];

export const ADD_CLIENT_SESSIONS_ROLES = [Role.ADMIN, Role.SUPER_ADMIN, Role.GENERAL_MANAGER, Role.COORDINATOR];

export const ASSIGN_CLIENT_SESSIONS_ROLES = [Role.ADMIN, Role.SUPER_ADMIN, Role.GENERAL_MANAGER, Role.COORDINATOR];

export const MOVE_TO_SESSIONS_ROLES = [Role.ADMIN, Role.SUPER_ADMIN, Role.GENERAL_MANAGER, Role.COORDINATOR];

export const CHANGE_STATUS_SESSIONS_ROLES = [Role.ADMIN, Role.SUPER_ADMIN, Role.GENERAL_MANAGER, Role.COORDINATOR];

export const REQUIRED_CHECKLIST_ROLES = [Role.ADMIN, Role.SUPER_ADMIN, Role.GENERAL_MANAGER, Role.COORDINATOR];
export const INFO_CHECKLIST_ROLES = [
  Role.ADMIN,
  Role.SUPER_ADMIN,
  Role.GENERAL_MANAGER,
  Role.COORDINATOR,
  Role.DIRECTOR_MANAGER,
  Role.PARENT,
];

export const ADD_FAMILY_BY_ADMIN_ROLES = [Role.ADMIN, Role.SUPER_ADMIN, Role.GENERAL_MANAGER, Role.COORDINATOR];

export const UPDATE_FAMILY_ROLES = [Role.ADMIN, Role.SUPER_ADMIN, Role.GENERAL_MANAGER, Role.COORDINATOR, Role.PARENT];
export const DELETE_FAMILY_ROLES = [Role.ADMIN, Role.SUPER_ADMIN, Role.GENERAL_MANAGER];

export const THERAPIST_PAGE_ROLES = [
  Role.ADMIN,
  Role.SUPER_ADMIN,
  Role.GENERAL_MANAGER,
  Role.COORDINATOR,
  Role.DIRECTOR_MANAGER,
];
