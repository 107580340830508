import { HttpHeaders, HttpParams } from '@angular/common/http';
import { QueryFilter, QueryFilters } from '@core/models/query-params.models';

export class HttpUtils {
  static skipSuccessMessageHeader: string = 'skip_success_message';

  static skipSuccessMessage(headers: HttpHeaders = new HttpHeaders()): HttpHeaders {
    return headers.set(this.skipSuccessMessageHeader, 'true');
  }

  static filtersToHttpParams(httpParams: HttpParams, filters: QueryFilters): HttpParams {
    Object.keys(filters).forEach((key: keyof QueryFilters) => {
      const prefix: string = `filters`;

      const filter: QueryFilter = filters[key];

      Object.keys(filter).forEach((filterKey: string) => {
        let value: QueryFilter[keyof QueryFilter] = filter[filterKey as keyof QueryFilter];

        if (value instanceof Array) value = value.join(',');

        return (httpParams = httpParams.append(`${prefix}[${key}][${filterKey}]`, value as string));
      });
    });

    return httpParams;
  }
}
